<template>
  <div class="home_page_content" ref="store_goods_list">
    <div class="introduce">
      <h3>店铺介绍</h3>
      <div class="introduce_text">
        <input type="checkbox" id="exp" />
        <p>
          <label
            class="btn"
            for="exp"
            v-if="obj.storeIntroduce ? obj.storeIntroduce.length > 60 : false"
          ></label
          >{{ obj.storeIntroduce }}
        </p>
      </div>
      <van-swipe @change="onChange">
        <van-swipe-item v-if="storeVideo">
          <video
            :src="storeVideo"
            style="width: 100%; height: 100%"
            controls
            @play="bannerScroll = false"
            @pause="bannerScroll = true"
          ></video>
        </van-swipe-item>
        <van-swipe-item v-for="(item, index) in banner" :key="index">
          <img v-lazy="item" />
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator" v-if="bannerLength > 1">
            {{ current + 1 }}/{{ bannerLength }}
          </div>
        </template>
      </van-swipe>
    </div>
    <div class="sort">
      <p @click="switchover(0)" :class="index === 0 ? 'pitch_on' : ''">
        综合排序
      </p>
      <p @click="switchover(1)" :class="index === 1 ? 'pitch_on' : ''">
        销量
        <span>
          <svg-icon
            icon-class="home_more_icon1"
            :class="index === 1 && order ? 'pitch_on' : ''"
          />
          <svg-icon
            icon-class="home_more_icon1"
            :class="index === 1 && !order ? 'pitch_on' : ''"
          />
        </span>
      </p>
      <p @click="switchover(2)" :class="index === 2 ? 'pitch_on' : ''">
        价格
        <span>
          <svg-icon
            icon-class="home_more_icon1"
            :class="index === 2 && order ? 'pitch_on' : ''"
          />
          <svg-icon
            icon-class="home_more_icon1"
            :class="index === 2 && !order ? 'pitch_on' : ''"
          />
        </span>
      </p>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getInfo"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      offset="0"
    >
      <div class="goods_list" id="goods_list">
        <goods
          v-for="(item, index) in goods"
          :key="index"
          :title="item.commodityName"
          :goodsInfo="item"
          :storeInfo="obj"
        />
        <van-empty
          description="暂无商品"
          v-if="!goods.length && !loading"
          style="width: 100%"
        />
      </div>
    </van-list>
    <functionButton :homePageBtn="true" :domNode="$refs.store_goods_list" />
  </div>
</template>

<script>
import Goods from "@/components/Goods";
import { mapState } from "vuex";
export default {
  name: "store-home-page",
  components: { Goods },
  computed: {
    ...mapState("user", ["tzInfo", "isLogin", "userInfo"]),
  },
  data() {
    return {
      index: 0,
      order: true, //desc asc 升降序
      isActive: 1,
      goods: [],
      pageNum: 1,
      pages: 1,
      obj: {},
      storeVideo: "",
      banner: [],
      current: 0,
      bannerLength: 0,
      order: true,
      sortColumn: "",

      loading: false,
      finished: false,
      error: false,
      pageNum: 1,
    };
  },

  created() {
    // this.getInfo();
  },

  methods: {
    async getInfo() {
      if (this.pageNum === 1)
        this.Toast.loading({
          duration: 0,
          message: "加载中...",
          forbidClick: true,
        });
      const data = await this.$API.store.storeHomePage({
        object: {
          like: "",
          location: "",
          order: this.order ? "desc" : "asc",
          sortColumn: this.sortColumn, //排序字段
          storeId: this.$route.query.storeId,
          memberId: this.isLogin ? this.userInfo.id : undefined,
          userId: this.tzInfo ? this.tzInfo.id : "",
        },
        pageNum: this.pageNum,
        pageSize: 4,
      });
      if (data.data.code != "SUCCESS") {
        this.error = true;
        this.Toast.clear();
        return false;
      }

      this.obj = data.data.data;
      if (!this.banner.length) {
        this.obj.storeBanner.split(",").forEach((element) => {
          if (element.endsWith(".mp4")) {
            this.storeVideo = element;
          } else {
            this.banner.push(element);
          }
        });
        this.bannerLength = this.obj.storeBanner.split(",").length;
      }

      data.data.data.storeHomePageItems.forEach((item) => {
        item.newUser = this.obj.newUser;
        item.entity = item.storeTastingActivityInfoEntity;
        item.enterpriseId = this.obj.enterpriseId;
        item.filePath = item.filePath.split(",")[0].endsWith(".mp4")
          ? item.filePath.split(",")[1]
          : item.filePath.split(",")[0];
        if (
          item.orderCartListItems.length &&
          !item.orderCartListItems[0].isDelete
        ) {
          this.$set(item, "quantity", item.orderCartListItems[0].quantity);
        } else {
          this.$set(item, "quantity", 0);
        }
        this.goods.push(item);
      });
      this.$nextTick(function () {
        this.waterFall();
      });

      this.loading = false;
      if (this.pageNum >= Math.ceil(data.data.data.total / 4)) {
        this.finished = true;
      }

      this.pageNum++;
      this.Toast.clear();
    },

    onChange(index) {
      this.current = index;
    },
    switchover(i) {
      if (this.index === i && i === 0) return;
      if (this.index != i) this.order = true;
      this.index = i;
      switch (i) {
        case 0:
          this.sortColumn = "";
          break;
        case 1:
          this.order = !this.order;
          break;
        case 2:
          this.order = !this.order;
          this.sortColumn = "retailPrice";
          break;
        default:
          break;
      }
      if (this.index === 1) return;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.goods = [];
        this.pageNum = 1;
        this.loading = true;
        this.finished = false;
        this.getInfo();
      }, 500);
    },
    waterFall() {
      // 1. 设置container盒子的宽度
      //      注意：浏览器的可视区域的宽度 / 一个item元素的宽度 = 一行的排列的元素的个数
      let container = document.getElementById("goods_list");
      let item = document.getElementsByClassName("goods_item_box");
      if (!item.length) {
        container.style.height = "auto";
        return;
      }
      //获取元素的宽度(含border，padding)
      let width = item[0].offsetWidth;
      //计算出浏览器窗口的宽度
      let clientWidth = document.documentElement.clientWidth;
      //计算出应该放几列（向下取整）
      // let columnCount = Math.floor(clientWidth / width);
      let columnCount = 2;
      //设置容器（父盒子）的宽度
      // container.style.width = columnCount * width + "px";
      let left = container.offsetWidth - width * columnCount; //宽度间隔
      let height = 12; //高度间隔

      // 2.设置每一个item元素的排列位置
      //  第一行整体的top值都是0 后面的依次找上一行高度最小的容器，在它下面进行排列
      let hrr = [];
      for (let i = 0; i < item.length; i++) {
        //定位第一行的图片
        if (i < columnCount) {
          item[i].style.top = "0px";
          if (i) {
            item[i].style.left = i * width + left + "px";
          } else {
            item[i].style.left = i * width + "px";
          }
          hrr.push(item[i].offsetHeight + height);
        } else {
          //第一行之后的
          //选择总高度最小的列
          let min = Math.min(...hrr);
          let index = hrr.indexOf(min);
          //将每个元素定位到当前总高度最小的列下
          //index为0在左边 为1在右边 2以此类推
          item[i].style.top = min + "px";
          if (index) {
            item[i].style.left = index * width + left + "px";
          } else {
            item[i].style.left = index * width + "px";
          }
          //当前定位的元素加入该列
          hrr[index] += item[i].offsetHeight + height;
        }
      }
      if (hrr.length < 2) {
        container.style.height = `${hrr[0]}px`;
        return;
      }
      if (hrr[0] >= hrr[1]) {
        container.style.height = `${hrr[0]}px`;
      } else {
        container.style.height = `${hrr[1]}px`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home_page_content {
  position: absolute;
  top: 200px;
  width: 100%;
  bottom: 0;
  overflow-y: scroll;
  background: #f5f5f5;
}
.introduce {
  padding: 6px 16px 0;
  font-size: 0;
  background: #fff;
  h3 {
    padding-left: 8px;
    border-left: 4px solid #009a4d;
    font-size: 16px;
  }
  .introduce_text {
    display: flex;
    margin: 8px 0 0 12px;
    #exp {
      display: none;
    }
    #exp:checked + p {
      max-height: 200px;
      -webkit-line-clamp: 999; /*设置一个足够大的行数就可以了*/
      max-height: none;
    }
    #exp:checked + p .btn::before {
      display: none;
    }
    #exp:checked + p .btn::after {
      content: "收起";
    }
    #exp:checked + p::after {
      display: none;
    }
    p {
      transition: 0.3s max-height;
      color: #666;
      font-size: 14px;
      line-height: 1.5;
      max-height: 4.5em;
      // overflow: hidden;
      overflow: hidden; //超出部分隐藏
      position: relative;
      .btn {
        float: right;
        clear: both;
        color: royalblue;
      }
      .btn::after {
        content: "展开";
      }
      .btn::before {
        content: "...";
        position: relative;
        left: -10px;
        color: #333;
        transform: translateX(-100%);
      }
    }
    p::before {
      content: "";
      float: right;
      width: 0; /*设置为0，或者不设置宽度*/
      height: 100%;
      margin-bottom: -0.2rem;
    }
    p::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background: #fff;
    }
  }

  .close {
    overflow: hidden;
  }
  ::v-deep .van-swipe__track {
    margin-top: 12px;
    align-items: center;
  }
  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
  }
  img {
    width: 100%;
    height: 172px;
    // height: 210px;
  }
}
.sort {
  display: flex;
  padding: 12px 0 12px 16px;
  background: #fff;
  p {
    display: flex;
    color: #666666;
    margin-right: 46px;
    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 4px;
      .svg-icon {
        font-size: 7px;
        font-weight: bold;
        color: #666666;
      }
      .svg-icon:nth-child(1) {
        transform: rotate(-90deg);
      }
      .svg-icon:nth-child(2) {
        transform: rotate(90deg);
      }
      .pitch_on {
        color: #009a4d;
      }
    }
  }
  .pitch_on {
    color: #009a4d;
  }
}
.goods_list {
  margin: 15px;
  position: relative;
}
</style>
