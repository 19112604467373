<template>
  <div id="search" :style="show ? '' : 'height:0'">
    <van-search
      v-model="value"
      shape="round"
      background="#fff"
      placeholder="请输入名称、货号、规格、品牌搜索"
      :clearable="false"
      ref="search_input"
      maxlength="20"
    >
      <template #left-icon>
        <svg-icon icon-class="home_nav_search_icon" />
      </template>
      <template #right-icon>
        <van-button round @click="searchButton">搜索</van-button>
      </template>
    </van-search>
    <div class="search_history">
      <div class="title">
        <b>历史搜索</b>
        <svg-icon icon-class="del" @click="empty" />
      </div>
      <!-- <van-empty description="暂无历史记录" /> -->
      <div class="content">
        <p
          v-for="(title, index) in historyList"
          :key="index"
          @click="goSearchDetail(title)"
        >
          {{ title }}
        </p>
        <span v-show="!historyList.length">没有历史记录~</span>
      </div>
    </div>
    <div class="close" @click="doClose" v-show="show">
      <van-icon name="cross" />
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
export default {
  name: "search",
  props: {
    show: Boolean,
  },
  data() {
    return {
      value: "",
      isShow: this.show,
      historyList: [],
    };
  },
  watch: {
    show(val) {
      if (val) {
        // document.documentElement.style.overflow = "hidden";
        document.body.style.overflow = "hidden";
        document.getElementsByClassName("van-field__control")[0].focus();
      } else {
        // document.documentElement.style.overflow = "";
        document.body.style.overflow = "scroll";
        document.getElementsByClassName("van-field__control")[0].blur();
      }
    },
  },

  created() {},
  mounted() {
    if (JSON.parse(localStorage.getItem("historyList"))) {
      this.historyList = JSON.parse(localStorage.getItem("historyList"));
    }
  },
  activated() {
    this.value = "";
  },
  methods: {
    doClose() {
      this.$emit("close", this.value);
    },
    searchButton() {
      if (this.value) {
        if (!this.historyList.includes(this.value)) {
          // 没有搜索记录，把搜索值push进数组首位，存入本地
          if (this.historyList.length >= 20) {
            this.historyList.pop();
          }
          this.historyList.unshift(this.value);
          localStorage.setItem("historyList", JSON.stringify(this.historyList));
        } else {
          //有搜索记录，删除之前的旧记录，将新搜索值重新push到数组首位
          let i = this.historyList.indexOf(this.value);
          this.historyList.splice(i, 1);
          this.historyList.unshift(this.value);
          localStorage.setItem("historyList", JSON.stringify(this.historyList));
        }
      }

      if (this.value) {
        this.$router.push({
          name: "goods-list",
          query: {
            like: this.value,
          },
        });
      } else {
        this.$router.push({
          name: "goods-list",
        });
      }
    },
    goSearchDetail(title) {
      this.$router.push({
        name: "goods-list",
        query: {
          like: title,
        },
      });
    },
    empty() {
      if (this.historyList.length) {
        Dialog.confirm({
          title: "确定清除全部历史记录？",
          className: "delete_history_tips",
          overlayStyle: {
            zIndex: 100001,
          },
        })
          .then(() => {
            this.Toast.success("清空历史搜索成功");
            localStorage.removeItem("historyList");
            this.historyList = [];
          })
          .catch(() => {});
      } else {
        this.Toast("暂无历史记录");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#search {
  font-size: 14px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // max-width: 380px;
  // margin: 0 auto;
  height: 100%;
  z-index: 100000;
  background: #fff;
  transition: 0.5s all;
  overflow: hidden;
  .van-search {
    padding: 9px 15px 0;

    ::v-deep .van-search__content {
      //       width: 345px;
      // height: 30px;
      border: 1px solid #009a4d;
      .van-cell {
        padding: 0;
        overflow: inherit;
        .van-field__left-icon {
          display: flex;
          align-items: center;
        }
        .van-cell__value {
          .van-field__body {
            position: relative;

            .van-field__control {
              height: 28px;
              font-size: 12px;
              padding: 0 60px 0 4px;
            }
            .van-field__control::-webkit-input-placeholder {
              /* WebKit browsers */
              color: #999999;
            }
            .van-field__control:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: #999999;
            }
            .van-field__control::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: #999999;
            }
            .van-field__control:-ms-input-placeholder {
              /* Internet Explorer 10+ */
              color: #999999;
            }
            .van-field__right-icon {
              .van-button {
                position: absolute;
                top: -1px;
                right: -1px;
                padding: 0 16px;
                height: 30px;
                background: linear-gradient(180deg, #0ae377 0%, #009a4d 100%);
                border: none;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
  .search_history {
    padding: 0 15px;
    position: absolute;
    top: 39px;
    bottom: 60px;
    width: 100%;
    overflow-y: scroll;
    // padding-bottom: 100px;
    .title {
      margin: 24px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      b {
        font-size: 16px;
        color: #333333;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      p {
        line-height: 26px;
        background: #f5f5f5;
        border-radius: 13px;
        padding: 0 10px;
        color: #666;
        margin-right: 10px;
        margin-bottom: 10px;
      }
      span {
        flex: 1;
        text-align: center;
        color: #666;
      }
    }
  }
  .close {
    position: absolute;
    bottom: 20px;
    width: 30px;
    height: 30px;
    border: 1px solid #666;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    margin-left: -10px;
  }
}
</style>

<style>
.delete_history_tips {
  z-index: 100001 !important;
}
</style>
